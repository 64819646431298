<template>
<div class="wrapper">
  <AppHeader
    :gameState="gameState"
    @start="startQuest"
    @size-change="onSizeChange"
    @mines-change="onMinesChange"
  />
  <div class="main-wrapper">
    <main v-if="values.length">
      <div class="row" v-for="(row, y) in values" :key="y" :style="`font-size: calc( Min(100vw, 100vh) /${size});`">
        <div
          class="cell"
          v-for="(cell, x) in row"
          :key="x"
          :style="`color:${getColor(cell)}`"
          :class="[(!opened?.[y]?.[x]) && 'filled', (position?.[0] === x && position?.[1] === y) && 'current', opened?.[y]?.[x] === 3 && 'exit']"
          @click=" null && toggle(x, y)"
          @contextmenu.prevent="toggle(x, y, true)"
        >
          <span class="front">
            {{ (opened?.[y]?.[x] === 4 && "👁") || (values[y][x] === 10 ? "💣" : values[y][x] || "") }}
          </span>
          <span class="back"></span>
        </div>
      </div>
      <div class="overlay" :class="isWin && 'success'" v-if="isLose || isWin">
        <p>{{isWin ? 'YOU WIN!' : 'GAME OVER'}}</p>
        <div class="button-wrapper">
          <button @click="startQuest">PLAY AGAIN</button>
        </div>
      </div>
      <div class="controls">
        <button @click="handleKeydown({key:'7'})">↖</button>
        <button @click="handleKeydown({key:'8'})">↑</button>
        <button @click="handleKeydown({key:'9'})">↗</button>
        <button @click="handleKeydown({key:'4'})">🡐</button>
        <button @click="handleKeydown({key:' '})">👁</button>
        <button @click="handleKeydown({key:'6'})">🡒</button>
        <button @click="handleKeydown({key:'1'})">↙</button>
        <button @click="handleKeydown({key:'2'})">↓</button>
        <button @click="handleKeydown({key:'3'})">↘</button>
      </div>
    </main>
  </div>
</div>
</template>

<script setup>
import AppHeader from './AppHeader.vue'
import { ref, onMounted, computed, watch } from 'vue'
const DEFAULT_SIZE = 20;
const DEFAULT_MINES_AMOUNT = 100;
const WARDS_TOTAL = 3;
const isLose = ref(false)
const isExitReached = ref(false)
const values = ref([])
const opened = ref([])
const position = ref(null)
const size = ref(DEFAULT_SIZE)
const minesTotal = ref(DEFAULT_MINES_AMOUNT)


const gameState = computed( () => ({
  size: size.value,
  marked: opened.value.flatMap( (num) => num ).filter( el => el === 1).length,
  mines: minesTotal.value,
  wards: `${WARDS_TOTAL - wardsLeft.value}/${WARDS_TOTAL}`,
}))

const wardsLeft = computed( () => {
  return (
        WARDS_TOTAL -
        (opened.value?.flatMap((val) => val).filter((val) => val === 4).length ??
          0)
      );
})

const isWin = computed( () => {3
  if (isExitReached.value) return true;
  const isAllMarked = gameState.value.marked === minesTotal.value
  if (!isAllMarked) return false
  let res = true
  opened.value.forEach( (row, y) => {
    res = res && !row.some( (cell, x) => {
      return cell === 1 && values.value[y][x] !== 10
    })
  })
  return res
})

const move = (dirX, dirY, mark = false) => {
  if (isLose.value) return;
  toggle2(
    Math.min(Math.max(position.value[0] + dirX, 0), size.value),
    Math.min(Math.max(position.value[1] + dirY, 0), size.value),
    mark,
    true
  );
}

const placeWard = () => {
  const [x,y] = position.value
  if (wardsLeft.value <= 0) return;
  opened.value[y][x] = 4
  toggleNearestCells(x,y, true)
}

const toggle2 = (x, y, mark = false, go = false, ward = false) => {
      if (x < 0 || y < 0 || x >= size.value || y >= size.value) return;
      if (opened.value?.[y]?.[x] === 3) {
        isExitReached.value = true;
        return;
      }
      // if (wardsLeft.value <= 0 && ward) return;
      if (!opened.value[y]) opened.value[y] = [];
      if (go) {
        position.value = [x, y];
      }
      if (mark && !opened.value[y][x]) {
        opened.value[y][x] = 2;
        return;
      }
      if (opened.value?.[y]?.[x]) return;

      opened.value[y][x] = true;
      switch (values.value[y][x]) {
        case 0:
          setTimeout(() => toggleNearestCells(x, y, ward));
          return;
        case 10:
          if (ward) return
          isLose.value = true;
          break;
        default:
      }
    }

const toggle = (x, y, marker) => {
  if (x < 0 || y < 0 || x + 1 > size.value || y + 1 > size.value) return;
  if (!opened.value[y]) {
    opened.value[y] = [];
  }
  if (opened.value[y][x] === 2) return;
  if (marker) {
    if (opened.value[y][x] === 1) opened.value[y][x] = 0;
    else opened.value[y][x] = 1;
    return;
  }
  opened.value[y][x] = marker ? 1 : 2;

  if (values.value?.[y][x] === 10) {
    isLose.value = true;
  }
  if (values.value?.[y][x] === 0) {
    setTimeout(()=>toggleNearestCells(x, y))
  }
}
const toggleNearestCells = (x, y, ward) => {
  if (isLose.value || isWin.value) return;
  [y - 1, y, y + 1].forEach( i => {
    [x - 1, x, x + 1].forEach( j => {
      if (!opened?.[i]?.[j]) toggle2(j, i, false, false, ward);
    })
  })
}

const onSizeChange = (sz) => {
  size.value = sz
  startQuest()
}

const onMinesChange = (sz) => {
  minesTotal.value = sz
  startQuest()
}

const getColor = (value) => {
  switch(value) {
    case 1: return 'blue'
    case 2: return 'green'
    case 3: return 'red'
    case 4: return 'darkblue'
    case 5: return 'brown'
    case 6: return 'cadetblue'
    case 7: return 'black'
    case 8: return 'grey'
  }
}

// const startGame = () => {
//   console.log('START GAME', +size.value, +minesTotal.value)
//   if (+minesTotal.value >= (+size.value) ** 2) {
//     minesTotal.value = DEFAULT_MINES_AMOUNT
//     return;
//   }
//   const field = Array(size.value)
//     .fill()
//     .map(() => Array(size.value).fill(0));
//   const mns = [];
//   while (mns.length < minesTotal.value) {
//     const x = Math.floor(Math.random() * size.value);
//     const y = Math.floor(Math.random() * size.value);
//     if (!mns.some((mine) => mine.x === x && mine.y === y)) {
//       field[y][x] = 10;
//       mns.push({ x, y });
//     }
//   }
//   field.forEach((row, y) => {
//     row.forEach((cell, x) => {
//       if (cell === 10) return;
//       let res = 0;
//       if (field?.[y - 1]?.[x - 1] === 10) res += 1;
//       if (field?.[y - 1]?.[x] === 10) res += 1;
//       if (field?.[y - 1]?.[x + 1] === 10) res += 1;
//       if (field?.[y]?.[x - 1] === 10) res += 1;
//       if (field?.[y]?.[x + 1] === 10) res += 1;
//       if (field?.[y + 1]?.[x - 1] === 10) res += 1;
//       if (field?.[y + 1]?.[x] === 10) res += 1;
//       if (field?.[y + 1]?.[x + 1] === 10) res += 1;
//       field[y][x] = res;
//     });
//   });
//   opened.value = [];
//   // isWin.value = false;
//   isLose.value = false;
//   values.value = field;
// }

const startQuest = () => {
  isLose.value = false;
  isExitReached.value = false;
  opened.value = [];
  position.value = null;
  const field = Array(size.value)
    .fill()
    .map(() => Array(size.value).fill([]));
  const mines = [];
  while (mines.length < Math.min(minesTotal.value, size.value ** 2 / 2)) {
    const x = Math.floor(Math.random() * size.value);
    const y = Math.floor(Math.random() * size.value);
    if (!x && !y) continue;
    if (!mines.some((mine) => mine.x === x && mine.y === y)) {
      field[y][x] = 10; // MINE
      mines.push({ x, y });
    }
  }
  let exit = null;
  while (!exit) {
    const x = Math.floor(Math.random() * size.value / 2) + size.value / 2;
    const y = Math.floor(Math.random() * size.value / 2) + size.value / 2;
    if (!mines.some((mine) => mine.x === x && mine.y === y)) {
      // field[y][x] = 11; // EXIT
      exit = [x, y];
    }
  }

  field.forEach((row, y) => {
    row.forEach((cell, x) => {
      if (cell < 9) {
        let res = 0;
        if (field?.[y - 1]?.[x - 1] === 10) res += 1;
        if (field?.[y - 1]?.[x] === 10) res += 1;
        if (field?.[y - 1]?.[x + 1] === 10) res += 1;
        if (field?.[y]?.[x - 1] === 10) res += 1;
        if (field?.[y]?.[x + 1] === 10) res += 1;
        if (field?.[y + 1]?.[x - 1] === 10) res += 1;
        if (field?.[y + 1]?.[x] === 10) res += 1;
        if (field?.[y + 1]?.[x + 1] === 10) res += 1;
        field[y][x] = res;
      }
    });
  });
  values.value = field;
  opened.value[0] = [];
  opened.value[1] = [];
  opened.value[2] = [];
  opened.value[0][1] = [];
  opened.value[0][2] = [];
  opened.value[1][0] = [];
  opened.value[1][1] = [];
  opened.value[1][2] = [];
  opened.value[2][0] = [];
  opened.value[2][1] = [];
  opened.value[2][2] = [];
            
            
  opened.value[exit[1]] = [];
  opened.value[exit[1]][exit[0]] = 3;
  position.value = [0, 0];
}

const handleKeydown = ({key}) => {
  console.log("key", "[" + key + "]");
  if (isLose.value || isWin.value) {
    startQuest();
    return;
  }
  switch (key) {
    case " ":
      placeWard();
      break;
    case "ArrowLeft":
    case "4":
      move(-1, 0);
      break;
    case "ArrowRight":
    case "6":
      move(1, 0);
      break;
    case "ArrowUp":
    case "8":
      move(0, -1);
      break;
    case "ArrowDown":
    case "2":
      move(0, 1);
      break;
    case "9":
      move(1, -1);
      break;
    case "7":
      move(-1, -1);
      break;
    case "1":
      move(-1, 1);
      break;
    case "3":
      move(1, 1);
      break;
    case "r":
    case "R":
      return startQuest();
    default:
  }
}

watch(position, () => {
  toggle2(...position.value)
})

onMounted(() => {
  startQuest();
  document.addEventListener('keydown', handleKeydown)
})
</script>
<style lang="scss" scoped>
@keyframes appearanim {
  from {
    max-height: 0;
  }
  to {
    max-height: 100px;
  }
}
.wrapper {
  display: grid;
  height: calc(100vh - 20px);
  grid-template-rows: min-content 1fr;
  gap: 10px;
  padding: 10px;
  width: 100%;
  /* overflow: hidden; */
}
.main-wrapper {
  display: flex;
  align-items: center;
}
h3 {
  margin: 40px 0 0;
}
main {
  gap: 2px;
  width: Min(100vw, 100vh - 100px);
  max-width: Min(100vw, 100vh - 100px);
  height: Min(100vw, 100vh - 100px);
  margin: 0 auto;
  display: grid;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  background: rgba(grey, 0.4);
  border-radius: 4px;
  .row {
    display: flex;
    gap: 2px;
  }
  .overlay {
    &.success {
      background: rgba(green, 0.2);
    }
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 2vw;
    align-items: center;
    font-size: Min(6vw, 50px);
    justify-content: center;
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    position: absolute;
    background: rgba(red, 0.1);
    backdrop-filter: blur(3px);
    transition: all 3s ease;
    p {
      margin: 0;
      color: rgba(white, 0.8);
      text-shadow: 0 0 6px rgba(black, 0.7);
      animation: .4s ease-out appearanim;
      max-height: 100px;
      overflow: hidden;
    }
    button {
      letter-spacing: 1px;
      font-weight: 600;
      color: rgba(white, 0.8);
      cursor: pointer;
      padding: 24px;
      font-size: 20px;
      background: rgba(#558abb, 0.8);
      border-radius: 8px;
      outline: none;
      border-color: transparent;
      transition: transform ease 0.1s;
      &:hover,
      &:focus {
        background: rgba(#558abb, 1);
        transform: scale(1.1);
      }
    }
  }
}
.cell {
  .front,
  .back {
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    position: absolute;
  }
  .back {
    transform: rotateY(180deg);
    color: white;
  }
  span {
    width: 100%;
  }
  &.filled {
    transform: rotateY(180deg);
    background: rgba(lightblue, 0.4);
    transition: transform .3s;
    color: transparent !important;
    cursor: pointer;
  }
  &.current {
    outline: 2px solid yellow;
  }
  &.exit {
    outline: 2px solid green;
  }
  will-change: transform;
  font-size: .7em;
  transition: transform 0.7s;
  transform-style: preserve-3d;
  perspective: 200px;
  border-radius: Min(4px, .3vw);
  background: lightblue;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  font-weight: bold;
}
.controls {
  display: none;
  z-index: 20;
  position: fixed;
  bottom: 0;
  // height: 30vh;
  width: 100%;
  background: rgba(white,.2);
}
@media screen and (max-width: 600px) {
  .controls {
    display: grid;
    width: auto;
    width: 100%;
    margin: 0 auto;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    button{
      background: rgba(grey, .2);
      color: white;
      font-size: 40px;
      height: 80px;
      width: 80px;
      margin: auto;
      cursor: pointer;
    } 
  }
  
}
</style>
