<template>
  <GameWrapper />
</template>

<script>
import GameWrapper from './components/GameWrapper.vue'

export default {
  name: 'App',
  components: { GameWrapper }
}
</script>

<style lang="scss">
body {
  margin: 0;
  background: grey;
}
#app {
  background: rgb(30,26,105);
background: linear-gradient(90deg, rgba(30,26,105,1) 31%, rgba(36,71,121,1) 64%);
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
