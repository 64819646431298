<template>
  <header>
    
    <div class="stats">
      <button @click="$emit('start')">↺</button>
      <label style="margin-left: 20px;">
        <em>👁</em>
        {{gameState.wards}}
      </label>
    </div>    
    <div class="divider" />
    <div>
      <label><em>✣</em>
        <select :value="size" @change="$emit('size-change', +$event.target.value)">
          <option v-for="(sz, i) in sizes" :key="i" :value="sz">{{sz}}</option>
        </select>
      </label>
      <label>
        <em>💣</em>
        <select :value="mines" @change="$emit('mines-change', +$event.target.value)">
          <option v-for="(sz, i) in minesCount" :key="i" :value="sz">{{sz}}</option>
        </select>
      </label>
    </div>    
  </header>
</template>

<script>
export default {
  name: "AppHeader",
  props: ['gameState'],
  data: () => ({
    size: 10,
    mines: 10,
  }),
  computed: {
    minesCount() {
      return [10, 20, 50, 100, 200].filter(mc => mc < this.size ** 2)
    },
    sizes() {
      return [10,20,30]
    }
  },
  updated() {
    this.size = this.$props?.gameState?.size ?? 10
    this.mines = this.$props?.gameState?.mines ?? 10
  },
  onMounted() {
    this.size = this.$props?.gameState?.size ?? 10
    this.mines = this.$props?.gameState?.mines ?? 10
  }
};
</script>

<style lang="scss" scoped>
header {
  color: white;
  min-height: 60px;
  // border: 1px solid lightgrey;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 4px 10px;
  box-sizing: border-box;
  border-radius: 10px;
  .stats {
    font-size: 40px;
  }
  em {
    margin-right: 8px;
    font-style: normal;
    line-height: 40px;
    height: 40px;
    font-size: 30px;
  }
  button, select {
    font-size: 30px;
    padding: 0 6px;
    line-height: 38px;
    height: 40px;
    letter-spacing: 1px;
    font-weight: 600;
    color: rgba(white, 0.8);
    cursor: pointer;
    background: rgba(#558abb, 0.8);
    border-radius: 8px;
    outline: none;
    border-color: transparent;
    transition: transform ease 0.1s;
    &:hover,
    &:focus {
      background: rgba(#558abb, 0.6);
      transform: scale(1.1);
    }
  }
}
label:not(:last-child) {
  margin-right: 20px;
}
.divider {
  flex-grow: 1;
}
</style>